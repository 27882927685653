import { api } from 'src/services';

import revenueComparison from './revenueComparison';
import statistics from './statistics';
import exportReport from './export';

export const authApis = api.injectEndpoints({
  endpoints: (build) => ({
    revenueComparison: revenueComparison(build),
    statistics: statistics(build),
    exportReport: exportReport(build),
  }),
  overrideExisting: true,
});

export const { useRevenueComparisonMutation, useStatisticsMutation, useExportReportMutation } =
  authApis;
