import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';

import store from '../../store/Store';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

class SentryClass {
  init = () => {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.NODE_ENV,
      tracesSampleRate: 1.0,

      integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
    });
  };

  captureException = (error: any) => {
    Sentry.captureException(error);
  };

  sentryErrorHandler = (
    error: any,
    code: string | number | undefined,
    requestMethod: string | undefined,
    bodyMethod: string,
  ) => {
    const user = store.getState().authReducer.user;
    Sentry.withScope((scope) => {
      scope.setExtra('error', typeof error === 'object' ? JSON.stringify(error) : error);
      scope.setExtra('request', requestMethod);
      scope.setExtra('requestBody', bodyMethod ? bodyMethod : undefined);
      scope.setExtra('requestCode', code);
      scope.setExtra('userProfile', user.data);
      scope.setExtra('userId', user.data?.id);
      Sentry.captureException(new Error(`Request Error on ${requestMethod}`));
    });
  };
}

export default new SentryClass();
