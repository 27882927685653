import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import Pusher from 'pusher-js';

import { dispatch, useSelector } from 'src/store/Store';
import { appendBooking } from 'src/store/apps/schedule/ScheduleSlice';
import generateEvent from 'src/views/pages/schedule/helpers/generateEvent';
import moment from 'moment';

const PusherContext = createContext(null);

export const PusherProvider = ({ children }) => {
  const user = useSelector((state) => state.authReducer.user);
  const selectedStadium = useSelector((state) => state.configReducer.selectedStadium);
  const scheduleConfig = useSelector((state) => state.scheduleReducer.scheduleConfig);

  const [pusher, setPusher] = useState(null);
  const scheduleConfigRef = useRef(scheduleConfig); // Create a ref to hold the current scheduleConfig

  useEffect(() => {
    if (selectedStadium && user) {
      // Step 1: Initialize Pusher
      const pusherInstance = new Pusher('malaebapp', {
        cluster: 'm1',
        wsHost: process.env.REACT_APP_PUSHER_URL,
        wsPort: Number(process.env.REACT_APP_WSPORT),
        wssPort: Number(process.env.REACT_APP_WSSPORT),
        enabledTransports: ['ws', 'wss'],
        forceTLS: true,
        authEndpoint: `${process.env.REACT_APP_DOMAIN}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${user?.token}`,
            'Access-Control-Allow-Origin': '*',
          },
        },
      });

      // Connect and get the socket_id
      pusherInstance.connection.bind('connected', () => {
        const channel = pusherInstance.subscribe(
          `private-pitchOwnerBookings.${selectedStadium?.id}`,
        );

        // Listen for an event
        channel.bind('pitch_owner_bookings_live_changes', (booking) => {
          const _booking = { ...booking };
          try {
            _booking.start = moment(
              `${_booking.start_date} ${_booking.match_time}`,
              'YYYY-MM-DD HH:mm',
            ).toDate();
            _booking.end = moment(
              `${_booking.end_date} ${_booking.match_end_time}`,
              'YYYY-MM-DD HH:mm',
            ).toDate();
          } catch (error) {
            console.log(error);
          }

          dispatch(
            appendBooking({
              ...generateEvent(_booking),
              resourceId: scheduleConfigRef.current.showAllPitches ? _booking.pitch_id : undefined, // Use ref value
            }),
          );
        });
      });

      // Set Pusher instance to state for cleanup
      setPusher(pusherInstance);

      // Cleanup Pusher connection on unmount
      return () => {
        pusherInstance.disconnect();
      };
    }
  }, [selectedStadium, user]);

  useEffect(() => {
    scheduleConfigRef.current = scheduleConfig; // Update ref when scheduleConfig changes
  }, [scheduleConfig]);

  return <PusherContext.Provider value={{ pusher }}>{children}</PusherContext.Provider>;
};

export const usePusher = () => {
  return useContext(PusherContext);
};
