import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

const getParams = (params: Body) => {
  return (
    `?email=${encodeURIComponent(params?.email)}&date=${params?.date}` +
    params?.pitches?.map((id) => `&pitches[]=${id}`).join('')
  );
};

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, Body>({
    query: (params) => ({
      url: `v4/pitch-owner/statistics/export/public${getParams(params)}`,
      method: 'GET',
    }),
  });

interface Body {
  email: string;
  date: string;
  pitches: string[];
}
